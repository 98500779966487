import React from 'react';
import { Button, Radio, Table } from 'semantic-ui-react';

const LikertStatement = ({ name, questionID, statement, score, updateScore, numOfItems }) => {
  const anchors = ["Strongly Disagree (1)", "Disagree", "Neutral", "Agree", "Strongly Agree (5)"];

  // Ensure the anchors array is trimmed or expanded to match numOfItems
  const adjustedAnchors = anchors.slice(0, numOfItems);

  // Handle rating change
  const handleRate = (e, { value }) => {
    updateScore(questionID, value);
  };

  return (  
    <div className="likert">

      {/* <h4>{name}: {statement} ({score}) </h4> */}
      <h4>{name}: {statement} </h4>
      <Button icon='undo' onClick={()=>updateScore(questionID, "Reset")}> Reset</Button>
      <Table basic='very'>
        <Table.Body>
          <Table.Row>
            {adjustedAnchors.map((anchor, index) => (
              <Table.Cell key={index} textAlign='center'>
                {anchor}
              </Table.Cell>
            ))}
          </Table.Row>
          <Table.Row>
            {adjustedAnchors.map((anchor, index) => (
              <Table.Cell key={index} textAlign='center'>
                <Radio
                  name={`likert-${questionID}`}
                  value={index + 1} // Assuming score starts from 1
                  checked={score === index + 1}
                  onChange={handleRate}
                />
              </Table.Cell>
            ))}
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};

export { LikertStatement };
