import React from 'react';
import { ButtonGroup, Button, Checkbox } from 'semantic-ui-react';
import TunePageForm from '../components/TunePageForm';
const TunePage = ({mode, setMode, children}) => {
return (
	<div className="mode-changer margint cr ">
		{/* <div className="panel padded">		
			 <TunePageForm /> 
		</div> */}
		<div className="panel padded cr">
			
			<ButtonGroup>
				<Button onClick={() => setMode("color")} color={mode === "color" ? "blue" : ""}>Color</Button>
				<Button onClick={() => setMode("info")} color={mode === "info" ? "blue" : ""}>Info</Button>
			</ButtonGroup>
			{children}
		</div>
	</div> 
);
};

export {TunePage};
