
// import QRCode from 'qrcode.react';

import React from 'react';
import { ButtonGroup, Button, Checkbox } from 'semantic-ui-react';
const ReviewPage = ({children, interpolate, setInterpolate}) => {
  return (
    <div>
        <div className="mode-changer margint cr">
          <ButtonGroup>
            <Button onClick={()=> setInterpolate(true)} color={interpolate ? "blue": ""}>Interpolate</Button>
            <Button onClick={()=> setInterpolate(false)}  color={!interpolate ? "blue": ""}>Raw</Button>
          </ButtonGroup>
        </div>
      {children}
    </div> 
  );
};

export {ReviewPage};



// const ReviewPage = ({ gridData, surveyResponses }) => {
//   const [selectedItem, setSelectedItem] = useState("");
//   const [showQRCode, setShowQRCode] = useState(false); // Initially, QR code is not shown
//   const [qrCodeData, setQRCodeData] = useState('Your data here'); // Placeholder for QR code data

//   const handleSelectChange = (event) => {
//     setSelectedItem(event.target.value);
//   };

//   const handleQRCodeGenerate = () => {
//     setShowQRCode(true); // Show QR code when button is clicked
//     // Optionally update qrCodeData based on some logic
//   };

//   const getOverlayStyle = (itemIndex) => {
//     if (parseInt(selectedItem) === itemIndex) {
//       return { backgroundColor: 'rgba(0, 255, 0, 0.5)' };
//     }
//     return { display: 'none' };
//   };

//   return (
//     <div>
//       <select onChange={handleSelectChange} value={selectedItem}>
//         <option value="">Select a survey item</option>
//         {surveyResponses.map((_, index) => (
//           <option key={index} value={index}>
//             Item {index + 1}
//           </option>
//         ))}
//       </select>
//       <div className="grid-image-container">
//         {gridData && <img src={gridData} alt="Survey Grid" />}
//         <div className="overlay-container">
//           {surveyResponses.map((_, index) => (
//             <div key={index} className="overlay-item" style={getOverlayStyle(index)}></div>
//           ))}
//         </div>
//       </div>
//       <Button onClick={handleQRCodeGenerate} color="blue">Generate QR Code</Button>
//       {showQRCode && <QRCode value={qrCodeData} size={256} level={"H"} />} {/* Render QR code if showQRCode is true */}
//     </div>
//   );
// };

// export default ReviewPage;
