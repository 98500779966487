import React from 'react'
import {
  ModalHeader,
  ModalDescription,
  ModalContent,
  ModalActions,
  Button,
  Header,
  Modal,
} from 'semantic-ui-react'

function ModalMessage({open, setOpen, trigger, header, header2, description, positiveAction, negativeAction, positiveText, negativeText}) {
  

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={trigger}
    >
      <ModalHeader>{header}</ModalHeader>
      <ModalContent>
       
        <ModalDescription>
          <Header>{header2}</Header>
          <p>{description}</p>
        </ModalDescription>
      </ModalContent>
      <ModalActions>
        <Button color='black' onClick={() => {
          setOpen(false);
          negativeAction();
          }}>
          {negativeText}
        </Button>
        <Button
          content={positiveText}
          labelPosition='right'
          icon='checkmark'
          onClick={() => {
            setOpen(false);
            positiveAction();
          }}
          positive
        />
      </ModalActions>
    </Modal>
  )
}

export {ModalMessage};

