import React, { useState } from 'react';
import { FormField, Button, Input, Form, Dropdown } from 'semantic-ui-react';

const TunePageForm = () => {
  const [title, setTitle] = useState('');
  const [sigma, setSigma] = useState('');
  const [strategy, setStrategy] = useState('');
  const [forms, setForms] = useState([]);
  const [selectedTune, setSelectedTune] = useState(''); 
  const [selectedOption, setSelectedOption] = useState('');

  const addForm = () => {
    setForms([...forms, { title, sigma, strategy }]);
    setTitle('');
    setSigma('');
    setStrategy('');
  };
  const handleDoneClick = () => {
    };

  const handleDropdownChange = (e, { value }) => {
    setSelectedOption(value);
  };

  return (
    <div className="fw fw">
      <Form className="form">
        <Form.Field className="searching">
        <div className="ui form">
          <div className="grouped fields">
            <label>Select a Tune Strategy</label>
            <div className="field">
              <div className="ui radio checkbox">
                <input 
                  type="radio" 
                  name="example2" 
                  checked={selectedTune === 'quick'}
                  onChange={() => setSelectedTune('quick')} 
                />
                <label>QuickTune</label>
              </div>
              {selectedTune === 'quick' && (
                <div className="ui small message">
                  <p>We chose the  <b>5 candidates</b> we think are the most useful.</p>
                  This is best when you need a quick solution.
                </div>
              )}
            </div>
            <div className="field">
              <div className="ui radio checkbox">
                <input type="radio" name="example2" 
                checked={selectedTune === 'exhaustive'}
                onChange={() => setSelectedTune('exhaustive')} 
                />
                <label>Exhaustive</label>
              </div>
              {selectedTune === 'exhaustive' && (
                <div className="ui small message">
                  <p>Evaluate all <b>25 candidates</b>.</p>
                </div>
              )}
            </div>
            <div className="field">
              <div className="ui radio checkbox">
                <input type="radio" name="example2" 
                checked={selectedTune === 'custom'}
                onChange={() => setSelectedTune('custom')}
                />
                <label>CustomTune</label>
              </div>
              {selectedTune === 'custom' && (
                <div className="ui small message">
                  <p>You can choose how many you want to evaluate.</p>
                  <Form.Field className="form-field">
                  <label className="label">Number of Candidates</label>
                  <Input type="number" className="input" />
                  </Form.Field>
                  <Form.Field className="form-field">
                    <Dropdown 
                      placeholder='Select Range Type' 
                      fluid 
                      selection 
                      options={[
                        { key: 'option1', text: 'Random Sampling', value: 'option1' },
                        { key: 'option2', text: 'Normal Sampling', value: 'option2' }
                      ]}
                      onChange={handleDropdownChange}
                    />
                  </Form.Field>
                  {selectedOption === 'option1' && (
                    <p>This is best when you have limited time.</p>
                  )}
                  {selectedOption === 'option2' && (
                    <p>This is best when you know your samples are normally distributed.</p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        </Form.Field>
        <Form.Field>
        <Button color="red" type="submit" onClick={handleDoneClick}>Done</Button>
        </Form.Field>
      </Form>
    </div>
  );
};

export default TunePageForm;