import React from 'react';

const Legend = ({
  minColor,
  minValue,
  maxColor,
  maxValue,
  orientation = 'horizontal',
  width = 300,
  height = 50,
  steps = 5, 
  unit
}) => {
  const gradientDirection = orientation === 'horizontal' ? 'to right' : 'to bottom';
  const gradient = `linear-gradient(${gradientDirection}, ${minColor}, ${maxColor})`;
  
  // Generate step labels
  const stepValues = Array.from({ length: steps }, (_, index) => {
    const range = maxValue - minValue;
    const stepSize = range / (steps - 1);
    return minValue + stepSize * index;
  });

  const legendContainerStyle = {
    display: 'flex',
    flexDirection: orientation === 'horizontal' ?  'column' : 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    // width: orientation === 'vertical' ? width : height, // Adjust these values as needed
    // height: orientation === 'vertical' ? height : width // Adjust these values as needed
  }
  const legendStyle = {
    display: 'flex',
    flexDirection: orientation === 'horizontal' ? 'row' : 'column',
    alignItems: 'center',
    width: orientation === 'vertical' ? width : height - 10, // Adjust these values as needed
    height: orientation === 'vertical' ? height - 10 : width, // Adjust these values as needed
    backgroundImage: gradient
  };

  const labelStyle = {
    // margin: orientation === 'horizontal' ? '0 10px' : '10px 0',
    fontSize: '12px',
  };

  return (
    <div className="legend" >
      <h4>Review Legend </h4>
      <div  className="legend-container" style={legendContainerStyle}>
      <div style={legendStyle} />
      <div className="legend-units" style={{ display: 'flex', height: height, flexDirection: orientation === 'horizontal' ? 'row' : 'column' }}>
        {stepValues.map((value, index) => (
          <div key={index} style={labelStyle}>
              {value.toFixed(0)} {unit}
              {index === 0 && <span> (min)</span>}
              {index === stepValues.length - 1 && <span> (max)</span>}
            </div>
          ))}
      </div>
      </div>
    </div>
  );
};

export {Legend};
