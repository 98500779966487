import React from 'react';
import { FormField, Form, TextArea } from 'semantic-ui-react';
import UploadWidget from './UploadWidget';
const OpenEndedInput = ({ name, questionID, statement, score, updateScore }) => {
  
  return (  
    <div className="openended">
      <h4>{name}: {statement} </h4>
      <Form>
        <FormField
            control={TextArea}
            label='Instruction'
            placeholder={statement}
            value={score}
            onChange={(e) => updateScore(questionID, e.target.value)}
            />
        <UploadWidget />
      </Form>
    </div>
  );
};

export { OpenEndedInput };
