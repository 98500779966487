import React, { useEffect } from 'react';
import { FormField, Input, TextArea, Button, Form } from 'semantic-ui-react';
import DimensionSubform from './DimensionSubform'; 

var hri = require('human-readable-ids').hri;


const TuneSpaceSettings = ({ setActiveTab, tuneSpace, setTuneSpace, children}) => {
  const addDimension = ()=> {
    if(tuneSpace.dimension_x.disabled){
      setTuneSpace({ ...tuneSpace, dimension_x: {...tuneSpace.dimension_x, disabled: false }});
    }else{
      setTuneSpace({ ...tuneSpace, dimension_y: {...tuneSpace.dimension_y, disabled: false }});
    }
  }
  return (
    <div className="fw">
      <Form className="form">
        {children}
        {/* TITLE */}
        <Form.Field className="form-field">
          <label className="label">Title</label>
          <Input 
            placeholder='Title' 
            value={tuneSpace.title}
            onChange={(e) => setTuneSpace({ ...tuneSpace, title: e.target.value })}
          />
        </Form.Field>

        <Form.Field className="form-field">
          <label className="label">Author</label>
          {/* <Input 
            placeholder='Author' 
            value={tuneSpace.author}
            onChange={(e) => setTuneSpace({ ...tuneSpace, author: e.target.value })}
          /> */}
          {tuneSpace.author}
        </Form.Field>

        

        {/* DESCRIPTION */}
        <Form.Field className="form-field">
          <label className="label">Description</label>
          <TextArea 
            placeholder='Description' 
            className="text-area"
            value={tuneSpace.description}
            onChange={(e) => setTuneSpace({ ...tuneSpace, description: e.target.value })}  
          />
        </Form.Field>

       

        {/* DIMENSION X SUBFORM */}
        {!tuneSpace.dimension_x.disabled && (
          <DimensionSubform 
            name="Dimension X"
            dimensionData={tuneSpace.dimension_x} 
            setDimensionData={(updatedDimension) => setTuneSpace({ ...tuneSpace, dimension_x: updatedDimension })}
          />
        )}

        {/* DIMENSION Y SUBFORM */}
        {!tuneSpace.dimension_y.disabled && (
          <DimensionSubform
            name="Dimension Y"
            dimensionData={tuneSpace.dimension_y} 
            setDimensionData={(updatedDimension) => setTuneSpace({ ...tuneSpace, dimension_y: updatedDimension })}
          />
        )}
        

        <FormField className="cr">
            { tuneSpace.dimension_y.disabled && (
              <Button primary onClick={addDimension}>
                ADD A DIMENSION
              </Button>
            )}
            {/* <Button color="red" onClick={()=> setActiveTab("/tunespace")}>
               DONE
            </Button> */}
            
        </FormField>
      </Form>
    </div>
  );
};

export default TuneSpaceSettings;