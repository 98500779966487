import axios from "axios";
const GRID_CANDIDATE_WIDTH = 120; 
const GRID_CANDIDATE_HEIGHT = 120; 
const COLOR_X = "#5B5F97";
const COLOR_Y = "#FF6B6C";
const COLOR_REVIEW_HIGH = "#1a9850";
const COLOR_REVIEW_LOW = "#54278f";
const NORMAL_RANGE = [0.2, 0.8];

const APIURL = "https://tune-api.cearto.com:3081";
//const APIURL = 'https://localhost:3081';
//const APIURL = 'https://crispy-spork-vprxvp5pxwfx9vj-3081.app.github.dev';
// const APIURL = 'https://crispy-spork-vprxvp5pxwfx9vj-3081.app.github.dev';
//  const APIURL = 'https://localhost:3081';
//const APIURL = 'https://crispy-spork-vprxvp5pxwfx9vj-3081.app.github.dev';
// const APIURL = 'https://redesigned-disco-674qvj56wjvh54pq-3081.app.github.dev';

const START_SCREEN = "GALLERY";

function systemLog(message){
    axios.post(APIURL + "/api/log", {message: message})
}

//  RESISTANCE V OUTER DIAMETER
// Human Tune Fuse
const humanTune = {
    tune_type: 'human_tune',
    model: "perler_bead",
    space: {
        title: "Iron Pressure on Fusing Perler Beads ",
        description: "How long should you keep the iron on the perler beads to fuse them together?",
        frame: "1D",
        dimension_x: {
            dimension: "How Hard?", 
            spread: "set", 
            range: [0, 1], 
            num_of_samples: 3, 
            set: ["Soft", "Medium", "Hard"],
            unit: ""
        },
        dimension_y: {
            dimension: "", 
            spread: "set", 
            range: [0, 1], 
            num_of_samples: 0, 
            set: [],
            unit: ""
        }
    },
    evaluation: {
        questions: [
        {
            type: "number-input",
            name: "Resistance",
            prompt: "Measure the resistance with an ohmeter.", 
            range: [1, 5000], 
            response: [],
            unit: "Ω"
        },
        ]
    }, 
    review: {
        interpolate: false, 
        criteria: ["Hold", "Remove", "Force"]
    }
} // Data from OpenSCAD

// PRESS FIT
const tuneTemplate = {
    "uuid": null, 
    "space" : {
        "tune_type" : "",
        "model" : "",
        "title" : "",
        "description" : "",
        "dimension_x" : {
            "dimension" : "",
            "spread" : "linear",
            "range" : [ 
                1, 
                10
            ],
            "unit" : "",
            "num_of_samples" : 1,
            normal_range : [],
            "set" : [],
            "disabled" : true
        },
        "dimension_y" : {
            "dimension" : "",
            "spread" : "linear",
            "range" : [ 
                1, 
                10
            ],
            "unit" : "",
            "num_of_samples" : 1,
            "set" : [],
            "disabled" : true
        }
    },
    "evaluation" : {
        "questions" : [ 
            {
                "type" : "likert",
                "name" : "Expectation",
                "prompt" : "The result is what I expected.",
                "range" : [ 
                    1, 
                    5
                ],
                "response" : [ 
                    0, 
                    0, 
                    0
                ]
            }
        ]
    },
    "review" : {
        "interpolate" : false,
    }
} 

const pressFitTune = {
    "uuid" : "space-donkey-27",
    "space" : {
        "tune_type" : "geometric",
        "model" : "perler_bead",
        "title" : "Salt Bae",
        "description" : "How much salt should I add to my pasta?",
        "frame" : "2D",
        "dimension_x" : {
            "dimension" : "Amount of Salt",
            "spread" : "linear",
            "range" : [ 
                1, 
                4
            ],
            "set" : [ 
                "1 Pinch", 
                "2 Pinch", 
                "3 Pinch"
            ],
            "num_of_samples" : 3,
            "unit" : "pinches"
        },
        "dimension_y" : {
            "dimension" : "Amount of Water",
            "spread" : "set",
            "range" : [ 
                100, 
                1000
            ],
            "unit" : "L",
            "num_of_samples" : 4,
            "set" : [ 
                "Tiny"
            ],
            "disabled" : false
        }
    },
    "evaluation" : {
        "questions" : [ 
            {
                "type" : "likert",
                "name" : "Hold",
                "prompt" : "The peg can effectively hold the perler bead",
                "range" : [ 
                    1, 
                    "7"
                ],
                "response" : [ 
                    0, 
                    0, 
                    0
                ]
            }, 
            {
                "type" : "likert",
                "name" : "Remove",
                "prompt" : "Perler bead can be removed easily from the peg",
                "range" : [ 
                    1, 
                    5
                ],
                "response" : [ 
                    0, 
                    0, 
                    0
                ]
            }, 
            {
                "type" : "likert",
                "name" : "Force",
                "prompt" : "Removing perler beads from the pegboard requires significant force",
                "range" : [ 
                    1, 
                    5
                ],
                "response" : [ 
                    0, 
                    0, 
                    0
                ]
            }, 
            {
                "type" : "likert",
                "name" : "QuestionX",
                "prompt" : "Add a likert statement here",
                "range" : [ 
                    1, 
                    5
                ],
                "unit" : "",
                "response" : [ 
                    0, 
                    0, 
                    0
                ]
            }
        ]
    },
    "review" : {
        "interpolate" : false,
        "criteria" : [ 
            "Hold", 
            "Remove", 
            "Force"
        ]
    }
} // Data from OpenSCAD


const generateZeroArray = (numCandidates, value=0)=> {
    let arr = [];
    for(let i = 0; i < numCandidates; i++){ arr.push(value);}
    return arr;
  }




// const tuneJSON = humanTune;
const tuneJSON = pressFitTune;

export {tuneTemplate, systemLog, generateZeroArray, START_SCREEN, APIURL, GRID_CANDIDATE_WIDTH, GRID_CANDIDATE_HEIGHT, NORMAL_RANGE, COLOR_REVIEW_HIGH, COLOR_REVIEW_LOW, COLOR_X, COLOR_Y, tuneJSON};
