import React, { useEffect } from 'react';
import { FormField, FormGroup, Segment, Select,  Input, TextArea, Button, Form } from 'semantic-ui-react';
import { generateZeroArray } from '../AppConfig';
const Question = ({ question, index, updateQuestion, removeQuestion, numOfQuestions }) => {
  const handleFieldChange = (field, value) => {
    const updatedQuestion = { ...question, [field]: value };
    updateQuestion(index, updatedQuestion);
  };
  
  return (
    <Segment className="question-item">
      <div className='crsb'>
        <h3> Question </h3>
        <Button circular icon='remove' onClick={() => removeQuestion(index)}/>
      </div>
      <Form>
        <FormField
            label="Abbreviation/Short Name"
            control={Input}
            placeholder="Name"
            value={question.name}
            onChange={(e) => handleFieldChange('name', e.target.value)}
        />
        <FormField>
          <label>Question Type</label>
          <Select
            placeholder="Type"
            options={[{ key: 'likert', value: 'likert', text: 'Likert' },
                      { key: 'numerical', value: 'numerical', text: 'Numerical' },
                      { key: 'openended', value: 'openended', text: 'Open-Ended Response' }]}
            value={question.type}
            onChange={(e, { value }) => handleFieldChange('type', value)}
          />
        </FormField>
        
        
        {question.type === "likert" && (
            <>
              <FormField
                control={Input}
                label="Likert Statement"
                placeholder="Add a statement for agreement or disagreement"
                value={question.prompt}
                type="text"
                onChange={(e) => handleFieldChange('prompt', e.target.value)}
              />
              <FormField
                control={Input}
                label='Likert Points'
                type="number"
                placeholder="Max"
                value={question.range ? question.range[1] : ''}
                onChange={(e) => handleFieldChange('range', [question.range ? question.range[0] : '', parseInt(e.target.value)])}
              />
            <FormGroup>
            <FormField
              control={Input}
              label='Weight'
              type="number"
              placeholder="Weight"
              value={question.weight ? question.weight : 1/numOfQuestions}
              onChange={(e) => handleFieldChange('weight', e.target.value)}
            />
            </FormGroup>
            </>
        )}
        {question.type === 'numerical' && (
          <>
            <FormField
              control={TextArea}
              label='Instruction'
              placeholder="Explain how to obtain the numerical reading"
              value={question.prompt}
              onChange={(e) => handleFieldChange('prompt', e.target.value)}
            />
            <FormGroup widths='equal'>
                <FormField
                  control={Input}
                  label='From'
                  type="number"
                  placeholder="Min"
                  value={question.range ? question.range[0] : ''}
                  onChange={(e) => handleFieldChange('range', [parseInt(e.target.value), question.range ? question.range[1] : ''])}
                />
                <FormField
                  control={Input}
                  label='To'
                  type="number"
                  placeholder="Max"
                  value={question.range ? question.range[1] : ''}
                  onChange={(e) => handleFieldChange('range', [question.range ? question.range[0] : '', parseInt(e.target.value)])}
                />
                <FormField
                  control={Input}
                  label='Unit'
                  type="text"
                  placeholder="Unit"
                  value={question.unit}
                  onChange={(e) => handleFieldChange('unit', e.target.value)}
                />
            </FormGroup>
            <FormGroup>
              <FormField
                  control={Input}
                  label='Weight'
                  type="number"
                  placeholder="Weight"
                  value={question.weight ? question.weight : 1/numOfQuestions}
                  onChange={(e) => handleFieldChange('weight', e.target.value)}
                />
            </FormGroup>
        </>
      )}
      {question.type === 'openended' && (
          <>
            <FormField
              control={TextArea}
              label='Instruction'
              placeholder="Explain what to write."
              value={question.prompt}
              onChange={(e) => handleFieldChange('prompt', e.target.value)}
            />
        </>
      )}
      </Form>
    </Segment>
  );
};


const EvaluationSettings = ({ tuneEvaluation, setTuneEvaluation, numCandidates }) => {
  const updateQuestion = (index, updatedQuestion) => {
    const updatedQuestions = [...tuneEvaluation.questions];
    updatedQuestions[index] = updatedQuestion;
    setTuneEvaluation({ ...tuneEvaluation, questions: updatedQuestions });
  };
  const removeQuestion = (index) => {
    const updatedQuestions = tuneEvaluation.questions.filter((_, i) => i !== index);
    setTuneEvaluation({ ...tuneEvaluation, questions: updatedQuestions });
  };

  //filter by question type numerical or likert, then count.
  const numOfQuantitativeQuestions = tuneEvaluation.questions.filter(question => question.type === 'numerical' || question.type === 'likert').length;
  return (
    <div className="fw">
      <Form className="form">
        <Form.Field>
          <label>Questions</label>
          {tuneEvaluation.questions.map((question, index) => (
            <Question
              key={index}
              question={question}
              index={index}
              updateQuestion={updateQuestion}
              removeQuestion={removeQuestion}
              numOfQuestions={numOfQuantitativeQuestions}
            />
          ))}
        </Form.Field>
        <Button
          icon="plus"
          onClick={() => {
            const updatedQuestions = [...tuneEvaluation.questions];
            updatedQuestions.push({
              type: "likert", // Default type, adjust as needed
              name: "QuestionX", // Default name
              prompt: "Add a likert statement here", // Default prompt
              range: [1, 5], // Default range, adjust as needed
              unit: "", // Default unit, adjust as needed
              response: generateZeroArray(numCandidates, null)
            });
            setTuneEvaluation({ ...tuneEvaluation, questions: updatedQuestions });
            
          }}
        >
          Add Question
        </Button>
      </Form>
    </div>
  );
};


export default EvaluationSettings;