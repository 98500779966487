import React, { useState } from 'react';
import {Legend} from './Legend';
import {TuneCandidate} from './TuneCandidate';
import {GRID_CANDIDATE_WIDTH, GRID_CANDIDATE_HEIGHT, COLOR_X, COLOR_Y, COLOR_REVIEW_HIGH, NORMAL_RANGE, COLOR_REVIEW_LOW} from '../AppConfig';
import {Button, Progress, Label, Message} from 'semantic-ui-react';
import { countEvaluatedCandidates } from '../AppMath';

const TuneSpace = ({ mode, tuneRef, tunePrint, setTunePrint, tuneSpace, setTuneSpace, activeTab, setActiveTab, tuneEvaluation, setTuneEvaluation, activeTuneCandidate, setActiveTuneCandidate, interpolate, setInterpolate}) => {
  const gridDisplay = ["/review", "/tunespace", "/home"]

  const numColumns = tuneRef.numColumns
  const numRows = tuneRef.numRows

  const gridWidth = (numColumns * GRID_CANDIDATE_WIDTH);
  const gridHeight = (numRows * GRID_CANDIDATE_HEIGHT);
  
  const numCandidates = numColumns * numRows;
  const numEvaluated = countEvaluatedCandidates(tunePrint);

  let gridStyle = {}
  if(gridDisplay.includes(activeTab)){
    // gridStyle = { width: `${gridWidth}px`, height: `${gridHeight}px` }
    gridStyle = { "min-width": `${gridWidth}px`, height: `${gridHeight}px` }
  }

  let rows = [];
  for (let row = 0; row < numRows; row++){ rows.push(row); }
  
  let cols = [];
  for (let col = 0; col < numColumns; col++){ cols.push(col); }
  
    
  const handleSettingsChange = (newSettings) => {
    // setSettings(newSettings);
    // setGridItems(new Array(parseInt(newSettings.numSamples)).fill(null));
  };

  const handleBackToGrid = () => {
    console.log("Saving response...")
    setTunePrint({...tunePrint, save: true});
    // setActiveTab("/tunespace",  {uuid: tunePrint.uuid});
  };

  if(gridDisplay.includes(activeTab)){
      return (
        <div className={`grid-layout ${activeTab.toLowerCase().slice(1)}`} >
 
        <h2 >{tuneSpace.title}</h2> 
        <p> {tuneSpace.description} </p>
       
        <p className='paddedt cc fw'>
        <Label basic>Candidates Evaluated</Label>
        <Progress value={numEvaluated} total={numCandidates} progress='ratio' />
        </p>
        {/*{JSON.stringify(tuneEvaluation)} */}
        <div className="grid-wrapper">
          
          <div className={`grid-container ${activeTab.toLowerCase().slice(1)}`} style={gridStyle}>
            {
              rows.map((row, rowIndex) => (
                <div key={rowIndex} className="grid-row">
                  {
                    cols.map((col, colIndex) => (
                      <TuneCandidate 
                        tuneRef={tuneRef}
                        tunePrint={tunePrint}
                        key={`${rowIndex}-${colIndex}`}
                        row={row}
                        col={col}
                        tuneSpace={tuneSpace}
                        tuneEvaluation={tuneEvaluation}
                        setTuneEvaluation={setTuneEvaluation}
                        activeTuneCandidate={activeTuneCandidate}
                        setActiveTuneCandidate={setActiveTuneCandidate}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        mode={mode}
                        interpolate={interpolate}
                        setInterpolate={setInterpolate}
                      />
                    ))
                  }
                </div>
              ))
            }
          </div>
          { tuneSpace.dimension_x.num_of_samples != 0 &&<div className="axis-label dimension-y x-axis-label">{tuneSpace.dimension_y.dimension}</div>}
          <div className="axis-label dimension-x y-axis-label">{tuneSpace.dimension_x.dimension}</div>
          { activeTab === "/review" && <Legend
            minColor={COLOR_REVIEW_LOW}
            minValue={tuneEvaluation?.questions[0].range[0]}
            maxColor={COLOR_REVIEW_HIGH}
            maxValue={tuneEvaluation?.questions[0].range[1]}
            orientation="vertical"
            width={30}
            height={100}
            steps={3}
            unit={tuneEvaluation?.questions[0].unit}
          />}
        </div>
       
      </div>
    );
  }else if(activeTab == "/evaluation" && activeTuneCandidate !== null){
    return (

        <div className={`grid-layout ${activeTab.toLowerCase().slice(1)}`} >        
              <div className={`${activeTab.toLowerCase().slice(1)}`} style={gridStyle}>
                {
                  rows.map((row, rowIndex) => (
                    <div key={rowIndex} className="tune-candidate-wrapper">
                    {
                      cols.map((col, colIndex) => {
                        const candidateKey = `${rowIndex}-${colIndex}`;
                        return activeTuneCandidate === candidateKey ? (
                          <TuneCandidate 
                            tuneRef={tuneRef}
                            tunePrint={tunePrint}
                            key={candidateKey}
                            row={row}
                            col={col}
                            tuneSpace={tuneSpace}
                            tuneEvaluation={tuneEvaluation}
                            setTuneEvaluation={setTuneEvaluation}
                            activeTuneCandidate={activeTuneCandidate}
                            setActiveTuneCandidate={setActiveTuneCandidate}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            mode={mode}
                            interpolate={interpolate}
                            setInterpolate={setInterpolate}
                          />
                        ) : null;
                      })
                        }
                      </div>
                      ))
                    }
                  </div>
          <div className="cr">
            <Button primary size="large" onClick={handleBackToGrid}>
              Done
            </Button>
          </div>
      
        </div>
    );

  }
  else if(activeTab == "/evaluation" && activeTuneCandidate === null){
    return (
      <div className="ui text container padded">
        <Message warning>        
          <Button primary floated='right' onClick={()=> setActiveTab("/tunespace")}> BACK </Button>
            Select a Tune Candidate to Evaluate
          <div className="clearfix"/>
        </Message>
      </div>
    );

  }
  
};

export {TuneSpace};
