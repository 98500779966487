import {useEffect, useRef} from "react";




const UploadWidget = ({ onUpload }) => {
    const cloudinaryRef = useRef();
    const widgetRef = useRef();
    useEffect(() => {
        cloudinaryRef.current = window.cloudinary;
        
        console.log(cloudinaryRef.current);
        widgetRef.current = cloudinaryRef.current.createUploadWidget({
            cloudName: 'cearto',
            uploadPreset: 'wod2u5lz'
        });
    }, []);
}


export default UploadWidget;