
import axios from 'axios';
import { useState } from 'react';
import { Icon, Button, Label, Input, Dropdown, DropdownDivider} from 'semantic-ui-react';
import { APIURL } from '../AppConfig';  
const TopMenu = ({ children, tunePrint, newTuneExploration, activeTab, setActiveTab, activeUser, setActiveUser}) => {
  const [studyId, setStudyId] = useState('');

  const handleInputChange = (event) => {
    setStudyId(event.target.value);
  };

  const handleSignIn = () => {
    // query axios with a post to /api/user
    // set studyID as uid in data
    // then parse json
    // then set activeUser as pseudoname

    axios.post(`${APIURL}/api/user`, {uid: studyId})
      .then(response => {
        response.data.success ? setActiveUser(response.data.user) : setStudyId("Invalid Study ID");
      })
      .catch(error => {
        console.log(error);
      });
  }
    

  const goTo = (tab)=>{
    setActiveTab(tab, {uuid: tunePrint.uuid})
  }

  return (
    <div className="top-bar fw">
      
      <div className="menu-content">
      <nav className="top-menu">
      <div
        className={`menu-item ${activeTab === '/gallery' ? 'active' : ''}`}
        onClick={() => goTo("/gallery")}
      >
        GALLERY
      </div>
     
      <div
        className={`menu-item ${activeTab === '/tunespace' ? 'active' : ''}`}
        disabled={tunePrint.uuid === null ? "disabled" : ""}
        onClick={() => goTo("/tunespace")}
      >
        TUNESPACE
      </div>
      <div
        className={`menu-item ${activeTab === '/review' ? 'active' : ''}`}
        disabled={tunePrint.uuid === null ? "disabled" : ""}
        onClick={() => goTo("/review")}
      >
        REVIEW
      </div>
    </nav>
      </div>
      <div className="menu-floated">
        {children}
        <div>
        { !activeUser && (
            <Input
              action={{
                color: 'teal',
                labelPosition: 'right',
                icon: 'sign-in',
                content: 'Sign In',
                onClick: handleSignIn
              }}
              placeholder='Study ID'
              value={studyId}
              onChange={handleInputChange}
            />
          )}
          
        { activeUser && (
            <Button.Group color='teal'>
                <Button> <Icon name='user' />{activeUser.pseudoname}</Button>
                <Dropdown
                  className='button icon'
                >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={newTuneExploration} > <Icon name="plus" /> New Tune </Dropdown.Item>
                    <Dropdown.Divider/>
                    <Dropdown.Item onClick={() => {setActiveUser(null); setStudyId("")}}> <Icon name='sign-out' /> Sign Out</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
            </Button.Group>
          )}

        <Button.Group>
          { activeTab !== "/gallery" && (
            <Button onClick={() => activeTab === '/home'? goTo("/tunespace") : goTo("/home")} className={`menu-item ${activeTab === '/home' ? 'active' : ''}`} icon>
              <Icon name="cog" />
            </Button>
          )}
          
        
        </Button.Group>
      </div>
    </div>
      
    </div>
  );
};

export default TopMenu;
