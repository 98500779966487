import React, { Children, useEffect } from 'react';
import {Segment, FormGroup, Input, Form, Dropdown,Message, Label, Icon, Button, FormField, MessageContent, MessageHeader} from 'semantic-ui-react';

const DimensionSubform = ({ dimensionData, setDimensionData, name}) => {

  useEffect(() => {
    if(dimensionData.spread === 'interval'){
      console.log(dimensionData)
      if(dimensionData.interval !== undefined){
        // Destructure necessary properties
        const { range, interval, num_of_samples } = dimensionData;

        // Calculate new range end value
        const newRangeEnd = range[0] + (interval * num_of_samples);

        // Update dimensionData with the new range while keeping other values
        setDimensionData({ 
          ...dimensionData, 
          range: [range[0], newRangeEnd] 
        });
      }else{
        setDimensionData({ ...dimensionData, interval: 10 }); // DEFAULT INTERVAL VALUE
      }
    }
  }, [dimensionData.spread, dimensionData.interval, dimensionData.num_of_samples, dimensionData.range[1]]);

  // Dynamic Units
  const baseOptions = [
    { key: 'percent', text: '%', value: '%' },
    { key: 'mm', text: 'mm', value: 'mm' },
    { key: 'cm', text: 'cm', value: 'cm' }
  ];
  
  const dimensionOption = { key: dimensionData.unit, text: dimensionData.unit, value: dimensionData.unit };
  
  // Combine the base options with the dimension option
  let options = [...baseOptions, dimensionOption];
  
  // Filter out duplicate keys
  options = options.filter((option, index, self) =>
    index === self.findIndex((t) => t.key === option.key)
  );
  
  const resetDimension = () => {
    setDimensionData({
      dimension: "",
      spread: "linear",
      range: [0, 100],
      unit: "percent",
      num_of_samples: 0,
      set: [], 
      disabled: true
    });
  }

  return (
    <Segment>
      <div className='crsb'>
        <h3> {name} </h3>
        <Button circular icon='remove' onClick={resetDimension}/>
      </div>
      <Form.Field className="form-field">
        <label className="label">Name</label>
        <Input 
            placeholder='Name' 
            className="input"
            value={dimensionData.dimension}
            onChange={(e) => setDimensionData({ ...dimensionData, dimension: e.target.value })}
        />
      </Form.Field>

      <Form.Field className="form-field">
          <label className="label">Spread</label>
          <Dropdown 
          placeholder='Select a Dimension Spread' 
          fluid 
          selection 
          options={[
            { key: 'Linear', text: 'Linear', value: 'linear' },
            { key: 'Interval', text: 'Interval', value: 'interval' },
            { key: 'Set', text: 'Set', value: 'set' },
            { key: 'Normal', text: 'Normal', value: 'normal' }
            
          ]}
          value={dimensionData.spread}
          onChange={(e, data) => setDimensionData({ ...dimensionData, spread: data.value })}
        />
        </Form.Field>
      

      
        { dimensionData.spread === 'linear' && (
          <>
            <FormGroup inline>
          <label className="label">Range</label>
          <Input 
            min={0}
            type="number" 
            className="input" 
            value={dimensionData.range[0]}
            onChange={(e) => setDimensionData({ ...dimensionData, range: [parseFloat(e.target.value), dimensionData.range[1]] })}
            />
          <label className="label">To</label>
          <Input
            min={0}
            type="number" 
            value={dimensionData.range[1]}
            onChange={(e) => setDimensionData({ ...dimensionData, range: [dimensionData.range[0], parseFloat(e.target.value)] })}
            />
          <Dropdown 
            placeholder='Select Range Type' 
            fluid 
            selection
            search 
            allowAdditions
            options={options}
            value={dimensionData.unit}
            onAddItem={(e, { value }) => setDimensionData({ ...dimensionData, unit: value })}
            onChange={(e, data) => setDimensionData({ ...dimensionData, unit: data.value })}
          />
          
            </FormGroup>
            <FormGroup>
          <Form.Field className="form-field">
              <label className="label">Number of Candidates</label>
              <Input 
            type="number" 
            className="input" 
            value={dimensionData.num_of_samples}
            onChange={(e) => setDimensionData({ ...dimensionData, num_of_samples: parseInt(e.target.value) })}
              />
            </Form.Field>
            </FormGroup>
            </>
          )}
          {/* Interval */}
          { dimensionData.spread === 'interval' && (
          <>
          

          <FormGroup>
            <FormField>
              <label className="label">Start</label>
              <Input 
                min={0}
                type="number" 
                className="input" 
                value={dimensionData.range[0]}
                onChange={(e) => setDimensionData({ ...dimensionData, range: [parseFloat(e.target.value), dimensionData.range[1]] })}
                />
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField>
              <label className="label">Interval</label>
              <Input
                min={0}
                type="number" 
                value={dimensionData.interval || 10}
                onChange={(e) => setDimensionData({ ...dimensionData, interval: parseFloat(e.target.value)})}
                />
            </FormField>
            
            <FormField className="form-field"> 
              <label className="label">Unit</label>
              <Dropdown 
                placeholder='Select Range Type' 
                fluid 
                selection
                search 
                allowAdditions
                options={options}
                value={dimensionData.unit}
                onAddItem={(e, { value }) => setDimensionData({ ...dimensionData, unit: value })}
                onChange={(e, data) => setDimensionData({ ...dimensionData, unit: data.value })}
              />
            </FormField>
          </FormGroup>
          <FormGroup>
            <Form.Field className="form-field">
                <label className="label">Number of Candidates</label>
                <Input 
              type="number" 
              className="input" 
              value={dimensionData.num_of_samples}
              onChange={(e) => setDimensionData({ ...dimensionData, num_of_samples: parseInt(e.target.value)})}
                />
            </Form.Field>
          </FormGroup>
          <Message color="yellow">
            <MessageHeader>Computed Range</MessageHeader>
            <MessageContent> {dimensionData.range[0]} {dimensionData.unit} to {dimensionData.range[0] + ((dimensionData.num_of_samples - 1) *  dimensionData.interval)} {dimensionData.unit}; {dimensionData.interval} steps; {dimensionData.num_of_samples} candidates </MessageContent>
          </Message>
          </>
        )}
      {/* SET */}
      {dimensionData.spread === 'set' && (
        <FormGroup inline>
          <label className="label">Set</label>
          <Dropdown
            placeholder='Select values'
            fluid
            multiple
            search
            selection
            allowAdditions
            options={dimensionData.set.map((value) => ({ key: value, text: value, value }))}
            value={dimensionData.set}
            onAddItem={(e, { value }) => setDimensionData({ ...dimensionData, set: [...dimensionData.set, value] })}
            onChange={(e, { value }) => setDimensionData({ ...dimensionData, set: value })}
            onRemoveItem={(e, { value }) => setDimensionData({ ...dimensionData, set: dimensionData.set.filter(item => item !== value) })}
          />
        </FormGroup>
        
      )}
      { dimensionData.spread === 'normal' && (
        <FormGroup inline>
          <label className="label">Range</label>
          <Input 
            type="number" 
            className="input" 
            value={dimensionData.range[0]}
            onChange={(e) => setDimensionData({ ...dimensionData, range: [parseInt(e.target.value), dimensionData.normal_range[1]] })}
            />
          <label className="label">To</label>
          <Input 
            type="number" 
            value={dimensionData.range[1]}
            onChange={(e) => setDimensionData({ ...dimensionData, range: [dimensionData.normal_range[0], parseInt(e.target.value)] })}
            />
          <Dropdown 
            placeholder='Select Range Type' 
            fluid 
            selection 
            options={[
              { key: 'percent', text: '%', value: 'percent' },
              { key: 'mm', text: 'mm', value: 'mm' },
              { key: 'cm', text: 'cm', value: 'cm' }
            ]}
            value={dimensionData.unit}
            onChange={(e, data) => setDimensionData({ ...dimensionData, unit: data.value })}
          />
          <Form.Field className="form-field">
            <label className="label">Number of Candidates</label>
            <Input 
              type="number" 
              className="input" 
              value={dimensionData.num_of_samples}
              onChange={(e) => setDimensionData({ ...dimensionData, num_of_samples: parseInt(e.target.value) })}
            />
          </Form.Field>
        </FormGroup>
        
        )}
      
      
    </Segment>
  );
};

export default DimensionSubform;