import React, { useEffect, useState } from 'react';
import {COLOR_X, COLOR_Y, COLOR_REVIEW_HIGH, NORMAL_RANGE, COLOR_REVIEW_LOW} from '../AppConfig';
import {linearInterpolate, dimensionInterporlate, mixColors} from './ColorManipulation';
import {NumberInput} from './NumberInput';
import {LikertStatement} from './LikertStatement';
import {Header, Icon, Button, Popup, PopupHeader, PopupContent, Rating} from 'semantic-ui-react';

import chroma from 'chroma-js';
import { jStat } from 'jstat';
import { indexToRowCol, euclideanDistance, manhattanDistance, areAllNonNull} from "../AppMath"
import { OpenEndedInput } from './OpenEndedInput';

const formatTuneCandidate = (x, precision) => {
    if (typeof x === 'number' && Number.isFinite(x)) {
        return x.toFixed(precision);
    } else {
        return x;
    }
};

const grabTuneValue = (tuneSpace, dimension, pos, numOfCandidates)=>{

    const tunePos = pos / (numOfCandidates-1); // value from 0 to 1
    const tuneRange = tuneSpace[dimension].range; // [min, max]
    const tuneSpread = tuneSpace[dimension].spread; // "linear"
    const tuneInterval = tuneSpace[dimension].interval || 10;
    

    const minVal = tuneRange[0];
    const maxVal = tuneRange[1];
    const range = maxVal - minVal;

    
    const mu = (range/2) + minVal;
    const sigma = range/4;
    
    let tuneValue = 0;

    if(tuneSpread === "linear"){
        tuneValue = (tunePos * range) + minVal;

    }else if(tuneSpread === "interval"){

        tuneValue = (pos * tuneInterval) + minVal;
    }else if(tuneSpread === "normal"){
        
        const normalRange = NORMAL_RANGE[1] - NORMAL_RANGE[0]
        const tunePosNormal = (tunePos * normalRange) + NORMAL_RANGE[0]
        tuneValue = jStat.normal.inv(tunePosNormal, mu, sigma);

    }else if(tuneSpread === "set"){

        const setCandidates = tuneSpace[dimension].set;
        tuneValue = setCandidates[pos];

    }

    // if (Number.isInteger(tuneValue)) {
    //     tuneValue = tuneValue; // Keep as integer
    // } else {
    //     tuneValue = parseFloat(tuneValue.toFixed(2)); // Format as float with 2 decimal points
    // }
    
    return [tuneValue, tunePos];
}

const TuneCandidate = ({tuneRef, mode, row, col, tunePrint, tuneSpace, tuneEvaluation, setActiveTuneCandidate, activeTuneCandidate, setTuneEvaluation, activeTab, setActiveTab, interpolate, setInterpolate}) => {

    // TuneSpace Properties
    const is1D = tuneSpace.dimension_y.disabled;
    const numColumns = tuneRef.numColumns
    const numRows = tuneRef.numRows
    const candidateIndex = (row * numColumns) + col;
    const [tuneXValue, tuneXPos] = grabTuneValue(tuneSpace, "dimension_x", row, numRows);
    const [tuneYValue, tuneYPos] = grabTuneValue(tuneSpace, "dimension_y", col, numColumns);

    // Derived Properties
 
    const [averageLikertScore, setAverageLikertScore] = useState(0);
    const [likertColor, setLikertColor] = useState(`rgb(255, 255, 255)`);
    const [tuneColor, setTuneColor] = useState(dimensionInterporlate(COLOR_X, COLOR_Y, tuneXPos, tuneYPos));
    const [evaluated, setEvaluated] = useState(false);
    

    // EFFECTS

    const updateScore = (questionIndex, newResponse) => {
        // console.log(questionIndex, candidateIndex, newResponse)
        if(tuneEvaluation){
            const updatedQuestions = tuneEvaluation.questions.map((question, index) => {
                // console.log(question, index)
                if (index === questionIndex) {
                    const newResponseArray = [...question.response];
                    newResponseArray[candidateIndex] = newResponse;
                    return { ...question, response: newResponseArray };
                }
                return question;
            });
            setTuneEvaluation({questions: updatedQuestions});
        }
    };

    useEffect(() => {
        if(evaluated){
            setTuneColor("")
        }else{
            setTuneColor(dimensionInterporlate(COLOR_X, COLOR_Y, tuneXPos, tuneYPos))
        }
    }, [evaluated, tuneXPos, tuneYPos, interpolate]);

    useEffect(() => {
        const likerts = tuneEvaluation.questions.filter(question => question.type === 'likert');
        const numericals = tuneEvaluation.questions.filter(question => question.type === 'numerical');

        const lscores = likerts.map(question => question.response[candidateIndex]);
        const nscores = numericals.map(question => question.response[candidateIndex]);

        // console.log("Scores for candidate", candidateIndex, "is evaluated", areAllNonNull(scores), ":", scores)
        const lfilled = areAllNonNull(lscores);
        const nfilled = areAllNonNull(nscores);

        setEvaluated(lfilled & nfilled);

        const sum = lscores.reduce((accumulator, currentValue) => accumulator + (currentValue || 0), 0);
        const nonNullValues = lscores.filter(value => value !== null).length;
        const avg = sum / nonNullValues;
        // console.log("AVG", avg, scores)

        setAverageLikertScore(avg);
    }, [tuneEvaluation]);

    const sendToReview = (key)=>{
        setActiveTab('/evaluation', {uuid: tunePrint.uuid, tc: key})
        // setActiveTuneCandidate(key)
    }
    const backToTuneSpace = ()=>{
        // setActiveTuneCandidate(null)
        setActiveTab('/tunespace', {uuid: tunePrint.uuid})
        
    }

    const getCandidateScores = (tuneEvaluation) => {
        const questions = tuneEvaluation.questions;
        // Filter only likert and numerical questions
        const filteredQuestions = questions.filter(q => q.type === 'likert' || q.type === 'numerical');

        // Get the number of questions and the length of the response arrays
        const numQuestions = filteredQuestions.length;
        if (numQuestions === 0) return [];

        const responseLength = filteredQuestions[0].response.length;

        // Initialize the weight vector (default: 1/num of likert + numerical questions)
        const weightVector = new Array(numQuestions).fill(1 / numQuestions);

        // Initialize an array to store the weighted sum
        const weightedSum = new Array(responseLength).fill(0);

        // Calculate the weighted sum
        filteredQuestions.forEach((question, i) => {
            question.response.forEach((response, j) => {
            weightedSum[j] += response * weightVector[i];
            });
        });

        return weightedSum;
    };



    // Review color update
    useEffect(()=> {
        const minLikertValue = tuneEvaluation.questions[0].range[0];
        const maxLikertValue = tuneEvaluation.questions[0].range[1];
        const avgLikertValue = averageLikertScore;
        const likertRange = (maxLikertValue - minLikertValue);
        const likertPos = (avgLikertValue - minLikertValue) / likertRange;

        // if averageLikertScore is a valid number
        
        if(isNaN(averageLikertScore)){
            const candidateScores = getCandidateScores(tuneEvaluation);
            const candidateDistances = candidateScores.map((score, candidateB)=> euclideanDistance(candidateIndex, candidateB, numColumns));
            
            const candidateColors = candidateScores.map((score, candidateB)=> {
                if(score > 0){
                    const pos = (score - minLikertValue) / (likertRange)
                    return linearInterpolate(COLOR_REVIEW_LOW, COLOR_REVIEW_HIGH, pos, 1)
                }else{
                    return null;
                }
            });
            
            setLikertColor(mixColors(candidateColors, candidateDistances))
        }else{
            // setEvaluated(true);
            setLikertColor(linearInterpolate(COLOR_REVIEW_LOW, COLOR_REVIEW_HIGH, likertPos, 1))
        }

        if(!interpolate){
            if(isNaN(averageLikertScore)){
                setLikertColor("");
                setEvaluated(false);
            }
        }

    }, [averageLikertScore, interpolate]);
    
return (
    <div className="tune-candidate">
        {activeTab === '/evaluation' && activeTuneCandidate === `${row}-${col}` && (
            <div className="fwh">
                <div className="cr">
                    <div
                        key={`${row}-${col}`}
                        className="grid-item cc"
                        onClick={() => backToTuneSpace()}
                        style={{ background: mode === "color" ? tuneColor : "" }}
                    >
                        {mode && mode === "info" && <span className="circle" style={{ background: tuneColor }}></span>}
                        {mode && mode === "info" && !tuneSpace.dimension_x.disabled && (
                            <span className="lp dimension-x">
                                {formatTuneCandidate(tuneXValue, 1)}
                                {tuneSpace.dimension_x.spread !== "set" && <span className="unit">{tuneSpace.dimension_x.unit}</span>}
                            </span>
                        )}
                        {mode && mode === "info" && !tuneSpace.dimension_y.disabled && (
                            <span className="lp dimension-y">
                                {formatTuneCandidate(tuneYValue, 1)}
                                {tuneSpace.dimension_y.spread !== "set" && <span className="unit">{tuneSpace.dimension_y.unit}</span>}
                            </span>
                        )}
                    </div>
                </div>

                {tuneEvaluation.questions[0].type === "likert" && <Header as="h3">Rate your agreement with the following statements:</Header>}
                {tuneEvaluation.questions[0].type === "numerical" && <Header as="h3">Fill out the following form:</Header>}
                {tuneEvaluation.questions[0].type === "openended" && <Header as="h3">Provide your response:</Header>}
                {tuneEvaluation.questions.map((question, questionID) => (
                    question.type === "likert" ? (
                        <LikertStatement
                            key={questionID}
                            name={question.name}
                            questionID={questionID}
                            statement={question.prompt}
                            score={tuneEvaluation.questions[questionID].response[candidateIndex]}
                            updateScore={updateScore}
                            numOfItems={question.range[1]}
                        />
                    ) : question.type === "numerical" ? (
                        <NumberInput
                            key={questionID}
                            name={question.name}
                            questionID={questionID}
                            statement={question.prompt}
                            score={tuneEvaluation.questions[questionID].response[candidateIndex]}
                            unit={tuneEvaluation.questions[questionID].unit}
                            updateScore={updateScore}
                        />
                    ) : question.type === "openended" ? (
                        <OpenEndedInput
                            key={questionID}
                            name={question.name}
                            questionID={questionID}
                            statement={question.prompt}
                            score={tuneEvaluation.questions[questionID].response[candidateIndex]}
                            updateScore={updateScore}
                        />
                    ) : null
                ))}
            </div>
        )}

        {["/tunespace", "/home"].includes(activeTab) && activeTuneCandidate === null && (
            <div
                key={`${row}-${col}`}
                className={`grid-item cc fw fh ${evaluated ? "evaluated" : ""}`}
                style={{ background: mode === "color" ? tuneColor : "" }}
                onClick={() => sendToReview(`${row}-${col}`)}
            >
                {mode && mode === "info" && <span className="circle" style={{ background: tuneColor }}></span>}
                {mode && mode === "info" && (
                    <span className="lp dimension-x">
                        {formatTuneCandidate(tuneXValue, 1)}
                        {tuneSpace.dimension_x.spread !== "set" && <span className="unit">{tuneSpace.dimension_x.unit}</span>}
                    </span>
                )}
                {mode && mode === "info" && !is1D && (
                    <span className="lp dimension-y">
                        {formatTuneCandidate(tuneYValue, 1)}
                        {tuneSpace.dimension_y.spread !== "set" && <span className="unit">{tuneSpace.dimension_y.unit}</span>}
                    </span>
                )}
                {mode && mode === "color" && evaluated && <Icon color="blue" className="check" />}
            </div>
        )}

        {activeTab === '/review' && activeTuneCandidate === null && (
            <Popup
                on="click"
                trigger={ <button
                key={`${row}-${col}`}
                className={`grid-item cc fw fh ${evaluated ? "evaluated" : ""}`}
                // onClick={() => sendToReview(`${row}-${col}`)}
                style={{ background: likertColor }}
                >
                    {!isNaN(averageLikertScore) && <span>{averageLikertScore.toFixed(2)}</span>}
                    {isNaN(averageLikertScore) && <span> - </span>}
                    
                </button>
                }
            >
                <PopupHeader>Dimensions</PopupHeader>
                <PopupContent>
                    {mode && mode === "info" && (
                        <span className="lp dimension-x large">
                            {formatTuneCandidate(tuneXValue, 1)}
                            {tuneSpace.dimension_x.spread !== "set" && <span className="unit">{tuneSpace.dimension_x.unit}</span>}
                        </span>
                    )}
                    {mode && mode === "info" && !is1D && (
                        <span className="lp dimension-y large">
                            {formatTuneCandidate(tuneYValue, 1)}
                            {tuneSpace.dimension_y.spread !== "set" && <span className="unit">{tuneSpace.dimension_y.unit}</span>}
                        </span>
                    )}
                    <div className="paddedt">
                    {tuneEvaluation.questions.map((question, questionID) => (
                        question.type === "likert" ? (
                            <LikertStatement
                                key={questionID}
                                name={question.name}
                                questionID={questionID}
                                statement={question.prompt}
                                score={tuneEvaluation.questions[questionID].response[candidateIndex]}
                                updateScore={updateScore}
                                numOfItems={question.range[1]}
                            />
                        ) : question.type === "numerical" ? (
                            <NumberInput
                                key={questionID}
                                name={question.name}
                                questionID={questionID}
                                statement={question.prompt}
                                score={tuneEvaluation.questions[questionID].response[candidateIndex]}
                                unit={tuneEvaluation.questions[questionID].unit}
                                updateScore={updateScore}
                            />
                        ) : question.type === "openended" ? (
                            <OpenEndedInput
                                key={questionID}
                                name={question.name}
                                questionID={questionID}
                                statement={question.prompt}
                                score={tuneEvaluation.questions[questionID].response[candidateIndex]}
                                updateScore={updateScore}
                            />
                        ) : null
                    ))}
                    </div>
                </PopupContent>
            </Popup>
        )}
    </div>
);

}


export {TuneCandidate};