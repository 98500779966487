import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { APIURL } from '../AppConfig';
import { Message, ButtonGroup, Button, Form, Checkbox, Icon} from 'semantic-ui-react';
import TuneSpaceSettings from '../components/TuneSpaceSettings';
import EvaluationSettings from '../components/EvaluationSettings';


const SettingsPage = ({numCandidates, setActiveTab, tunePrint, setTunePrint, tuneSpace, setTuneSpace, children, tuneEvaluation, setTuneEvaluation}) => {
  const [settingsMode, setSettingsMode] = useState("tunespace");

  const saveTuneSettings = () => {
    setTunePrint({...tunePrint, save: true}); 
    // setActiveTab("/tunespace", {uuid: tunePrint.uuid});
  }
  return (
    <div className="home-page fwh crfs padded">
      <div className="margint crsb fw">
        <ButtonGroup>
          <Button 
            onClick={() => setSettingsMode("tunespace")}
            primary={settingsMode === "tunespace"}
          >
            TuneSpace Settings
          </Button>
          <Button 
            onClick={() => setSettingsMode("evaluation")}
            primary={settingsMode === "evaluation"}
          >
            Evaluation Settings
          </Button>
        </ButtonGroup>
        <Button color="red" onClick={saveTuneSettings}> <Icon name="save"></Icon>SAVE</Button>
      </div>
      
      {settingsMode === "tunespace" && (
        <>
          <div className="panel padded">
         
     
            <TuneSpaceSettings
              setActiveTab={setActiveTab}
              tuneSpace={tuneSpace}
              setTuneSpace={setTuneSpace}
            >
               <Form.Field className="form-field">
                <label className="label">Public?</label>
                <Checkbox 
                  checked={tuneSpace.public}
                  onChange={(e, data) => setTunePrint({ ...tunePrint, public: data.checked })}
                />
              </Form.Field>
            </TuneSpaceSettings>
          </div>
          <div className="panel padded">
            <Message>Tune Experiment Preview</Message>
            {children}
            
          </div>
        </>
      )}

      {settingsMode === "evaluation" && (
        <>
          <div className="panel padded">
            <EvaluationSettings
              tuneEvaluation={tuneEvaluation}
              setTuneEvaluation={setTuneEvaluation}
              numCandidates={numCandidates}
            ></EvaluationSettings>
          </div>
          <div className="panel padded">
            <Message>Evaluation Settings Preview</Message>
          </div>
        </>
      )}

      

      
    </div>
  );
};

export {SettingsPage};