
function indexToRowCol(index, numCols) {
    return {
        row: Math.floor(index / numCols),
        col: index % numCols
    };
}

function euclideanDistance(index1, index2, numCols) {

    const pos1 = indexToRowCol(index1, numCols);
    const pos2 = indexToRowCol(index2, numCols);

    const rowDifference = pos1.row - pos2.row;
    const colDifference = pos1.col - pos2.col;

    const distance = Math.sqrt(rowDifference * rowDifference + colDifference * colDifference);
    // console.log(pos1, pos2, distance)
    return distance;
}

function manhattanDistance(index1, index2, numCols) {
    const pos1 = indexToRowCol(index1, numCols);
    const pos2 = indexToRowCol(index2, numCols);

    const rowDifference = Math.abs(pos1.row - pos2.row);
    const colDifference = Math.abs(pos1.col - pos2.col);

    return rowDifference + colDifference;
}

const areAllNonZero = (scores) =>{
    return scores.every(score => score !== 0);
}
const areAllNonNull = (scores) =>{
  return scores.every(score => score !== null && score !== undefined);
}

function countEvaluatedCandidates(catalog) {
    let quantitativeResponses = [];

    if(catalog?.evaluation?.questions?.length !== 0){
      // Extracting qunatitative questions and their responses
      catalog.evaluation.questions.forEach(question => {
        if (question.type === 'likert' || question.type === 'numerical') {
          quantitativeResponses.push(question.response);
        }
      });
    
      // Ensure all likert responses have the same length
      if (quantitativeResponses.length === 0) {
        return 0; // No likert responses found
      }
    
      const numQuestions = quantitativeResponses.length;
      const numResponses = quantitativeResponses[0].length;
      
      // quantitativeResponses represents a vertically stacked matrix of responses
      // Along the vertical axis, count the number of columns will all non-null responses
      let count = 0;
      for (let i = 0; i < numResponses; i++) {
        let response = [];
        for (let j = 0; j < numQuestions; j++) {
          response.push(quantitativeResponses[j][i]);
        }
        if (areAllNonNull(response)) {
          count++;
        }
      }
      return count;
      
    }
    else{
      return 0;
    }
    return 0;
  }


export { areAllNonZero, countEvaluatedCandidates, indexToRowCol, euclideanDistance, manhattanDistance, areAllNonNull}