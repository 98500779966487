import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

const NumberInput = ({ name, questionID, statement, score, updateScore, unit }) => {

  const handleInputChange = (e, { value }) => {
    const numberInputValue = Number(value);
    updateScore(questionID, numberInputValue);
  };

  return (
    <Form className="number-input paddedtb">
      <Form.Field>
        {/* <label>{name}: {statement} ({score} {unit}) </label> */}
        <label>{name}: {statement} </label>
        <Input
          type="text"
          placeholder={name} 
          value={score}
          onChange={handleInputChange}
          label={{ basic: true, content: unit}}
          labelPosition="right"
        />
      </Form.Field>
    </Form>
  );
};

export { NumberInput };
