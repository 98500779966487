import chroma from 'chroma-js';

function generateVariations(color, intensity) {
    // Create lighter and darker variations of a given color
    const lighter = chroma(color).brighten(intensity).hex();
    const darker = chroma(color).darken(intensity).hex();
    return [lighter, darker];
}

function dimensionInterporlate(colorX, colorY, tuneXPos, tuneYPos) {
    // Generate color variations for X and Y dimensions
    const [lighterX, darkerX] = generateVariations(colorX, 1.2);
    const [lighterY, darkerY] = generateVariations(colorY, 1.2);

    // Interpolate colors along X axis
    const yMix = chroma.mix(lighterY, darkerY, tuneYPos).hex();
    const xMix = chroma.mix(lighterX, darkerX, tuneXPos).hex();

    // // Bilinear interpolation
    const finalColor = chroma.mix(xMix, yMix, 0.5);
    // Return the final color as a CSS RGB string
    return finalColor.css();
}
function linearInterpolate(lighter, darker, pos, intensity){
    const finalColor = chroma.mix(lighter, darker, pos);
    return finalColor.css();
}

function mixColors(colors, distances) {
    // Filter out non-null colors and their corresponding distances
    const validColors = colors
        .map((color, index) => ({ color, distance: distances[index] }))
        .filter(pair => pair.color !== null);

    if (validColors.length === 0) {
        return null; // or a default color if needed
    }

    // Sort by distance and take the three closest pairs
    const closestPairs = validColors
        .sort((a, b) => a.distance - b.distance)
        .slice(0, 3);

    // Invert distances to use as mix weights (closer colors contribute more)
    const invertedDistances = closestPairs.map(pair => 1 / pair.distance);
    const totalInvertedDistance = invertedDistances.reduce((sum, invDist) => sum + invDist, 0);
    const normalizedWeights = invertedDistances.map(invDist => invDist / totalInvertedDistance);

    // console.log(colors, distances, closestPairs, normalizedWeights)
    // Mix colors
    let mixedColor = chroma(closestPairs[0].color).alpha(1);//normalizedWeights[0]);
    for (let i = 1; i < closestPairs.length; i++) {
        mixedColor = chroma.mix(mixedColor, closestPairs[i].color, normalizedWeights[i]);
    }

    return mixedColor.css();
}


export {dimensionInterporlate, linearInterpolate, mixColors};