import React, { useEffect, useState, useRef, useCallback} from 'react';
import 'semantic-ui-css/semantic.min.css';
import './App.css';
import {tuneJSON,
  APIURL,
  START_SCREEN,
  generateZeroArray,
  tuneTemplate, 
  systemLog
  } from './AppConfig';
import TopMenu from './components/TopMenu';
import { TuneSpace } from './components/TuneSpace';
import { TunePage } from './pages/TunePage';
import { ReviewPage } from './pages/ReviewPage';
import { GalleryPage } from './pages/GalleryPage';
import { SettingsPage } from './pages/SettingsPage';
import { ButtonGroup, Button, Checkbox, Icon } from 'semantic-ui-react';
import axios from 'axios';
import { ModalMessage } from './components/ModalMessage';


var hri = require('human-readable-ids').hri;

const App = () => {
  const [currentPage, setCurrentPage] = useState('');
  const [searchParams, setSearchParams] = useState(new URLSearchParams(window.location.search));

  // const [activeTab, setActiveTab] = useState(START_SCREEN);
  const [tunePrint, setTunePrint] = useState(tuneTemplate);
  const [activeTuneCandidate, setActiveTuneCandidate] = useState(null);
  const [mode, setMode] = useState('info');
  const [interpolate, setInterpolate] = useState(true);
  const [tuneRef, setTuneRef] = useState({uuid: null, numRows: 0, numColumns: 0, numCandidates: 0});
  const [dirty, setDirty] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeUser, setActiveUser] = useState(null);
  const [userconfirmed, setUserConfirmed] = useState(false);
  const tunePrintRef = useRef();

  // SETTERS
  const setTuneSpace = (newSpace) => {           setTunePrint({ ...tunePrint, space: newSpace });          };
  const setTuneEvaluation = (newEvaluation) => { setTunePrint({ ...tunePrint, evaluation: newEvaluation });};
  const setTuneReview = (newReview) => {         setTunePrint({ ...tunePrint, review: newReview });        };


  // Load value from localStorage when the component mounts
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('activeUser'));
    if (storedUser) {
      setActiveUser(storedUser);
    }
  }, []);

  // Save value to localStorage whenever it changes
  useEffect(() => {
    if (activeUser !== null) {
      localStorage.setItem('activeUser', JSON.stringify(activeUser));
    } else {
      localStorage.removeItem('activeUser');
    }
  }, [activeUser]);

  const loadCatalog = (uuid) => {
    axios.get(`${APIURL}/api/catalog/${uuid}`)
      .then((response) => {
        if(response.data.success){
          setTunePrint(response.data.data);
        }else{
          console.error("Failed to load tuneprint", response);
          setTunePrint({...tuneTemplate, space: {...tuneTemplate.space, author: activeUser.pseudoname}, uuid: uuid})
        }            
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    // if searchparams changed... update the internal tuneprint
    if(searchParams.get("uuid") !== tunePrint.uuid){
      const uuid = searchParams.get("uuid");
      if(uuid){
        loadCatalog(uuid);
      }
    }
    if(searchParams.get("tc") !== activeTuneCandidate){
      setActiveTuneCandidate(searchParams.get("tc", null));
    }
  }, [searchParams]);

  useEffect(() => {
    const url = new URL(window.location);
    const tunePrintTitle = tunePrint.space.title ? ` - ${tunePrint.space.title}` : "";
    const activeTuneCandidateTitle = activeTuneCandidate ? ` - ${activeTuneCandidate}` : "";
    const viewTitle = url.pathname.toLocaleUpperCase().slice(1);
    const title = `${viewTitle}${tunePrintTitle}${activeTuneCandidateTitle}`;
    document.title = title;
  }, [tunePrint, activeTuneCandidate]);

  useEffect(() => {
    const handlePopState = (event) => {
      console.log("POP", window.location.pathname, event.state, event.state.title)
      setCurrentPage(window.location.pathname);
      setSearchParams(new URLSearchParams(window.location.search));      
      if (event.state && event.state.title) {
        document.title = event.state.title;
      }
    };

    window.addEventListener('popstate', handlePopState);

    const queryParams = Object.fromEntries(new URLSearchParams(window.location.search).entries());
    navigateTo(window.location.pathname, queryParams, true);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);


  const navigateTo = (path, params = {}, replace=false) => {
  
    if(path === "/"){ path = "/gallery"; } // DEFAULT START
    const url = new URL(window.location.origin + path);
    
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

    const title = `${url.pathname.toLocaleUpperCase().slice(1)}`;
    if(replace){
      window.history.replaceState({ title }, title, url);
    }
    else{
      window.history.pushState({ title }, title, url);
    }
    setCurrentPage(url.pathname);
    setSearchParams(new URLSearchParams(url.search));
    systemLog(url)

  };




  const resetTunePrint = () => {
    setUserConfirmed(false); 
    setOpen(false);
    axios
      .get(`${APIURL}/api/catalog/${tunePrint.uuid}`)
      .then((response) => {
        response = response.data
        if(response.success){
          console.log("Tuneprint reloaded");
          setTunePrint({...response.data, save: false});
          tunePrintRef.current = tunePrint;
        }else{
          console.log(response.message); 
        }
      })
  }

  // Save tunePrint to the server
  const saveTunePrint = () => {
    if(!tunePrint || tunePrint.space.title === ""){
      console.log("NOT SAVING");
      return;
    }
    if(userconfirmed && tunePrint.save){
      setUserConfirmed(false);
      axios
      .post(`${APIURL}/api/tuneprint/hardsave`, tunePrint)
      .then((response) => {
        response = response.data
        if(response.success){
          console.log("Tuneprint hardsaved", response.merged_tuneprint);
          setTunePrint({...response.merged_tuneprint, save: false});
          tunePrintRef.current = tunePrint;
          setOpen(false);
          navigateTo("/tunespace",  {uuid: tunePrint.uuid});
        }else{
          console.log(response.message)
        }
      })
    }
    else if(tunePrint.save){
      axios
      .post(`${APIURL}/api/tuneprint`, tunePrint)
      .then((response) => {
        response = response.data
        if(response.success){
          console.log("Tuneprint saved");
          setTunePrint({...response.merged_tuneprint, save: false});
          tunePrintRef.current = tunePrint;
          navigateTo("/tunespace",  {uuid: tunePrint.uuid});
        }else{
          console.log(response.message, response.merged_tuneprint);
          setOpen(true);
                  // setTunePrint(response.merged_tuneprint);
          // 
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }
  };

 

  useEffect(saveTunePrint, [tunePrint.save, userconfirmed]);


  useEffect(() => {    
    if(! tunePrint?.space) return;
    
    const num_of_samples_y =
      tunePrint.space.dimension_y.spread === 'set'
        ? tunePrint.space.dimension_y.set.length
        : tunePrint.space.dimension_y.num_of_samples;
    
        const num_of_samples_x =
      tunePrint.space.dimension_x.spread === 'set'
        ? tunePrint.space.dimension_x.set.length
        : tunePrint.space.dimension_x.num_of_samples;
    
    const numColumns = tunePrint.space.dimension_y.disabled ? 1 : num_of_samples_y;
    const numRows = tunePrint.space.dimension_x.disabled ? 1 : num_of_samples_x;
    const numCandidates = numColumns * numRows;
    const uuid = tunePrint.uuid;

    setTuneRef({uuid, numRows, numColumns, numCandidates});

    if (uuid && uuid == tuneRef.uuid){ // we are still in the same catalog
      console.log("Updating to", uuid, numCandidates, numRows, "x", numColumns)
      console.log("SAME CATALOG", tuneRef.uuid, uuid)
      if(numRows !== tuneRef.numRows || numColumns !== tuneRef.numColumns) { // but there was a change
        console.log("Dirty space detected!", numRows, tuneRef.numRows, numColumns, tuneRef.numColumns, numCandidates, tuneRef.numCandidates)
        const updatedQuestions = tunePrint.evaluation.questions.map((question) => {
          return { ...question, response: generateZeroArray(numCandidates, null) };
        });
        setTuneEvaluation({ ...tunePrint.evaluation, questions: updatedQuestions });
      }
    }
    
  }, [tunePrint?.space]);




  const newTuneExploration = () => {
    navigateTo("/home", {uuid: hri.random()})
  }
  

  

  const saveFile = () => {
    const fileName = tunePrint.uuid + ".json";
    const fileContent = JSON.stringify(tunePrint, null, 2); // prettify the JSON
    const blob = new Blob([fileContent], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(url);
  };

  const renderPage = () => {

    switch (currentPage) {
      case '/gallery':
        return <GalleryPage
            activeUser={activeUser}
            setActiveTab={navigateTo}
            setTunePrint={setTunePrint}
          />;
      case '/tunespace':
        return (
          <TunePage setMode={setMode} mode={mode}>
            <TuneSpace
              tuneRef={tuneRef}
              tunePrint={tunePrint}
              activeTab={currentPage}
              tuneSpace={tunePrint.space}
              setTuneSpace={setTuneSpace}
              setActiveTab={navigateTo}
              tuneEvaluation={tunePrint.evaluation}
              setTuneEvaluation={setTuneEvaluation}
              activeTuneCandidate={activeTuneCandidate}
              setActiveTuneCandidate={setActiveTuneCandidate}
              mode={mode}
              interpolate={interpolate}
              setInterpolate={setInterpolate}
            />
          </TunePage>
        );
      case '/evaluation':
        return <TuneSpace
          tuneRef={tuneRef}
          setTunePrint={setTunePrint}
          activeTab={currentPage}
          tunePrint={tunePrint}
          tuneSpace={tunePrint.space}
          setTuneSpace={setTuneSpace}
          setActiveTab={navigateTo}
          tuneEvaluation={tunePrint.evaluation}
          setTuneEvaluation={setTuneEvaluation}
          activeTuneCandidate={activeTuneCandidate}
          setActiveTuneCandidate={setActiveTuneCandidate}
          mode={mode}
          interpolate={interpolate}
          setInterpolate={setInterpolate}
        />;
      case '/review':
        return (
        <>
          <ReviewPage setInterpolate={setInterpolate} interpolate={interpolate} />
          <TuneSpace
            tuneRef={tuneRef}
            activeTab={currentPage}
            tunePrint={tunePrint}
            tuneSpace={tunePrint.space}
            setTuneSpace={setTuneSpace}
            setActiveTab={navigateTo}
            tuneEvaluation={tunePrint.evaluation}
            setTuneEvaluation={setTuneEvaluation}
            activeTuneCandidate={activeTuneCandidate}
            setActiveTuneCandidate={setActiveTuneCandidate}
            mode={mode}
            interpolate={interpolate}
            setInterpolate={setInterpolate}
          />  
          </>
        );
      case '/home':
        return <SettingsPage
            setActiveTab={navigateTo}
            tuneSpace={tunePrint.space}
            setTuneSpace={setTuneSpace}
            tuneEvaluation={tunePrint.evaluation}
            setTuneEvaluation={setTuneEvaluation}
            numCandidates={tunePrint.numCandidates} 
            setTunePrint={setTunePrint}
            tunePrint={tunePrint}
          >
            <Button color="yellow" onClick={()=> console.log(tunePrint, JSON.stringify(tunePrint))}>
               DEBUG
            </Button>
            <TuneSpace
              tuneRef={tuneRef}
              tunePrint={tunePrint}
              activeTab={currentPage}
              tuneSpace={tunePrint.space}
              setTuneSpace={setTuneSpace}
              setActiveTab={navigateTo}
              tuneEvaluation={tunePrint.evaluation}
              setTuneEvaluation={setTuneEvaluation}
              activeTuneCandidate={activeTuneCandidate}
              setActiveTuneCandidate={setActiveTuneCandidate}
              mode={mode}
              interpolate={interpolate}
              setInterpolate={setInterpolate}
            />
            
          </SettingsPage>;
    }
  };

  return (
    <>
      <TopMenu
        activeTab={currentPage}
        setActiveTab={navigateTo}
        setActiveTuneCandidate={setActiveTuneCandidate}
        newTuneExploration={newTuneExploration}
        tunePrint={tunePrint}
        activeUser={activeUser}
        setActiveUser={setActiveUser}
      >
        <Button.Group>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(tunePrint?.uuid ? window.location : "NO ACTIVE TUNE");
            }}
          >
            {tunePrint?.uuid ? tunePrint.uuid : "NO ACTIVE TUNE"}
          </Button>
          <Button icon color="blue" onClick={saveFile} disabled={tunePrint?.uuid ? "" : "disabled"}>
            <Icon name="download"/>
          </Button>
        </Button.Group>
      </TopMenu>
  
      <div className="content">
        {renderPage()}
        
        <ModalMessage
          trigger={null}
          header="Watch Out!"
          header2="Data Loss Warning"
          description="Making that change will reset your tunespace. You'll lose the data you have already collected."
          positiveAction={resetTunePrint}
          positiveText="Go back to safety"
          negativeAction={()=>{ setUserConfirmed(true);}}
          negativeText="Go ahead and change it!"
          open={open}
          setOpen={setOpen}
        />
      </div>
    </>
  );
};

export default App;
