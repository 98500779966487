import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, Grid, Card,CardGroup, Dropdown, DropdownMenu, DropdownItem, DropdownDivider, Message, Input, Progress, ModalContent, ModalActions, Header, Icon, Modal, ButtonGroup } from 'semantic-ui-react';
import { APIURL, tuneTemplate } from '../AppConfig';
import { countEvaluatedCandidates } from '../AppMath';
import { ignore } from '@cloudinary/url-gen/qualifiers/rotationMode';
var hri = require('human-readable-ids').hri;


const GenericModal = function({messageHeader, message, positiveAction, negativeAction, positiveLabel, negativeLabel, positiveColor, negativeColor, positiveIcon, negativeIcon, buttonLabel, buttonColor}) {
  
    const [open, setOpen] = useState(false)
    
    const positiveHandler = ()=>{
      if(positiveAction) positiveAction();
      setOpen(false);
    }
    const negativeHandler = ()=>{
      if(negativeAction) negativeAction();
      setOpen(false);
    }
    return (
      <Modal
        basic
        onClose={(event) => setOpen(false)}
        onOpen={(event) => {event.stopPropagation(); setOpen(true)}}
        open={open}
        size='small'
        trigger={<DropdownItem color={buttonColor}><Icon name={positiveIcon}/>{buttonLabel}</DropdownItem>}
      >
        <Header icon>
          {messageHeader}
        </Header>
        <ModalContent>
          <p>
            {message}
          </p>
        </ModalContent>
        <ModalActions>
          <Button basic color={negativeColor}inverted onClick={negativeHandler}>
            <Icon name={negativeIcon} /> {negativeLabel}
          </Button>
          <Button color={positiveColor} inverted onClick={positiveHandler}>
            <Icon name={positiveIcon} /> {positiveLabel}
          </Button>
        </ModalActions>
      </Modal>
    )
}

const GalleryItem = ({ catalog, setTunePrint, setActiveTab, fetchCatalogs }) => {
  const activateTuneSpace = () => {
    setActiveTab("/tunespace", {uuid: catalog.uuid});
    console.log("Active Tune Catalog", catalog)
  } 
  const deleteTuneSpace = () => {
    console.log("Delete Tune Catalog", catalog)
    axios.delete(`${APIURL}/api/catalog/${catalog.uuid}`)
      .then(response => {
        console.log("Deleted catalog", catalog.uuid);
        setTunePrint(tuneTemplate);
        setActiveTab("/gallery");
        fetchCatalogs();
      })
      .catch(error => {
        console.error("Failed to delete catalog:", error);
      });
  }
  const duplicateTuneSpace = (event) => {
    console.log("Duplicate Tune Catalog", catalog)
    event.stopPropagation();
    const cloneUUID = hri.random();
    setTunePrint({...catalog, space:{...catalog.space, title: (catalog.space.title + " (COPY)") } , uuid: cloneUUID});
    setActiveTab("/home", {uuid: cloneUUID})
  }
  const num_of_samples_y = catalog.space.dimension_y.spread === "set" ? catalog.space.dimension_y.set.length : catalog.space.dimension_y.num_of_samples;
  const num_of_samples_x = catalog.space.dimension_x.spread === "set" ? catalog.space.dimension_x.set.length : catalog.space.dimension_x.num_of_samples;

  const numColumns = catalog.space.dimension_y.disabled ? 1 : num_of_samples_y;
  const numRows = catalog.space.dimension_x.disabled ? 1 : num_of_samples_x;

  const numCandidates = numColumns * numRows;
  const numEvaluated = countEvaluatedCandidates(catalog);

  return (
    <>
      {!catalog.space.disabled && (
        <Card key={catalog.id}>
          <Card.Content>
            <Card.Header as="a" onClick={activateTuneSpace}>{catalog.space.title}</Card.Header>
            <Card.Meta>{catalog.space.author}</Card.Meta>
            <Card.Meta>{catalog.space.description}</Card.Meta>
            <Card.Description>
              <br />
              {!catalog.space.dimension_x.disabled && (
                <>
                  <strong>Dimension X:</strong> {catalog.space.dimension_x.dimension} 
                  {catalog.space.dimension_x.unit !== "" && <> ({catalog.space.dimension_x.unit})</>}
                  <br />
                </>
              )}
              {!catalog.space.dimension_y.disabled && (
                <>
                  <strong>Dimension Y:</strong> {catalog.space.dimension_y.dimension} 
                  {catalog.space.dimension_y.unit !== "" && <> ({catalog.space.dimension_y.unit})</>}
                  <br />
                </>
              )}
              <strong>Candidates:</strong>
              <Progress value={numEvaluated} total={numCandidates} progress='ratio' />
              
              <Button.Group color='teal'>
                <Button onClick={activateTuneSpace}> View </Button>
                <Dropdown
                  className='button icon'
                >
                  <DropdownMenu>
                    <DropdownItem onClick={duplicateTuneSpace}><Icon name='copy'/>Clone</DropdownItem>
                    <DropdownDivider></DropdownDivider>
                    <GenericModal 
                      messageHeader={`Are you sure you want to delete ${catalog.space.title}?`} 
                      message="This action cannot be reversed." 
                      positiveAction={deleteTuneSpace}
                      positiveLabel= {`Yes, delete ${catalog.space.title}.`}
                      negativeLabel= {`Whoops! No, don't delete that.`}
                      positiveColor= "red"
                      positiveIcon="trash"
                      buttonLabel="Delete"
                      buttonColor="red"/>
                    
                  </DropdownMenu>
                </Dropdown>
              </Button.Group>
            </Card.Description>
          </Card.Content>
        </Card>
      )}
    </>
  );
}


const GalleryPage = ({ activeUser, setActiveTab, setTunePrint, children }) => {
  const [catalogs, setCatalogs] = useState([]);
  const [error, setError] = useState(false); // State to manage error visibility
  const [fetching, setFetching] = useState(false);
  const fetchCatalogs = () => {
    setFetching(true);
    axios.get(`${APIURL}/api/catalogs`)
      .then(response => {
        if(activeUser){
          let userCatalogs = response.data.filter(catalog => catalog.space?.author === activeUser.pseudoname || catalog.public === "true" || activeUser.admin);
          setCatalogs(userCatalogs.reverse());
          setError(false); // Clear error on successful fetch
          setFetching(false);
        }else{
          console.error("Not logged in:", error);
          setError(true); // Set error state to true on failure
          setFetching(false);
        }
      })
      .catch(error => {
        console.error("Failed to fetch catalogs:", error);
        setError(true); // Set error state to true on failure
        setFetching(false);
      });
  };

  useEffect(() => {
    fetchCatalogs();
  }, [activeUser]);

  const [searchTerm, setSearchTerm] = useState(""); // State to store search term
  const [filteredCatalogs, setFilteredCatalogs] = useState([]); // State to store filtered catalogs

  // Function to handle search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to filter catalogs based on search term
  const filterCatalogs = () => {
    const filtered = catalogs.filter((catalog) => {
      var inTitle = false;
      var byAuthor = false;
      if("title" in catalog.space){
        let title = catalog.space.title.toLowerCase()
        inTitle = title.includes(searchTerm.toLowerCase());
      }
      if("author" in catalog.space){
        let author = catalog.space.author.toLowerCase()
        byAuthor = author.includes(searchTerm.toLowerCase());
      }
      
      return inTitle || byAuthor;
    });
    setFilteredCatalogs(filtered);
  };

  useEffect(() => {
    filterCatalogs();
  }, [searchTerm, catalogs]);

  return (
    <div className="home-page fwh crfs paddedlr">
      <div className="search-bar padded">
        {activeUser && <Input type="text" placeholder="Search catalog" value={searchTerm} onChange={handleSearchChange} />}
      </div>
      <div className="crfs paddedtb fwh"> 
      {error ? (
        <Message negative>
          { !activeUser && <Message.Header> Looks like you aren't signed in. Try signing in above.</Message.Header>}
          { activeUser && (<>
            <Message.Header> We're sorry, we couldn't retrieve your catalogs.</Message.Header>
            <p>Please try again later.</p>
            <Button color="red" icon='refresh' className={fetching?"loading":""} onClick={fetchCatalogs}>Refresh</Button> {/* Refresh button */}
          </>)}
        </Message>
      ) : (catalogs.length === 0 || filteredCatalogs.length == 0)? (
        <div className="ui negative message">
          <div className="header">No catalogs found</div>
          <p>Please try again later.</p>
        </div>
      ) : (
        <CardGroup className="fw" stackable itemsPerRow={3} >
          {filteredCatalogs.map((catalog) => (
            <GalleryItem catalog={catalog} setActiveTab={setActiveTab} setTunePrint={setTunePrint} fetchCatalogs={fetchCatalogs}/>
          ))}
        </CardGroup>
      )}
      </div>
    </div>
  );
};

export {GalleryPage};